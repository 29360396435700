import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { isExternalLink } from '../../../utils/links';
import Link from '../link/link';
// @ts-ignore
import Styles from './footer.module.scss';

interface Footer {
    data: {
        body: {
            primary: {
                title: {
                    text: string;
                };
            };
            items: {
                link: {
                    text: string;
                };
                link_text: {
                    text: string;
                };
            }[];
        }[];
    }
}

const footerQuery = graphql`
{
    footer: prismicFooter {
        data {
            body {
                ... on PrismicFooterBodyLinkGruppe {
                    primary {
                        title {
                            text
                        }
                    }
                    items {
                        link {
                            text
                        }
                        link_text {
                            text
                        }
                    }
                }
            }
        }
    }
}
`;

export default () => {
    const data: { footer: Footer } = useStaticQuery(footerQuery);
    return (
        <React.Fragment>
            <div className="pt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-5 col-lg-4 offset-md-7 offset-lg-8" style={{ marginBottom: '-2px' }}>
                            <h1 className="text-uppercase color-grey mb-0" style={{ lineHeight: 0.8 }}>
                                <span className="font-weight-normal ml-5">Die</span><br></br>
                                <span>Nestbau</span><br></br>
                                <span>Experten</span>
                            </h1>
                        </div>
                    </div>
                </div>
                <footer>
                    <img className="d-none d-lg-block position-absolute" src="/images/specht_331x400.png"></img>
                    <div className="container pt-3">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-lg-3 offset-lg-3 mb-4 mb-lg-0">
                                <address>
                                    <h2>Oster Dach + Holzbau GmbH</h2>
                                    Moselbahnstraße 16<br></br>
                                    54470 Bernkastel-Kues
                                </address>
                                <a className="unstyled" href="tel:+49653150040">Tel.: 06531 5004 0</a><br></br>
                                <a className="unstyled" href="mailto:info@osterdach.de">info@osterdach.de</a>
                            </div>
                            {data.footer.data.body.map((slice, index) => (
                                <div key={index} className="col-12 col-sm-6 mb-sm-4 mb-lg-0 col-lg-2">
                                    <h2>{slice.primary.title.text}</h2>
                                    <ul className="list-unstyled">
                                        {
                                            slice.items.map((listItem, index) => (
                                                <li key={index}>
                                                    {isExternalLink(listItem.link.text) ?
                                                        (<a className="unstyled" href={listItem.link.text} target="_blank" rel="noopener noreferrer">{listItem.link_text.text}</a>) :
                                                        (<Link className="unstyled" to={listItem.link.text}>{listItem.link_text.text}</Link>)
                                                    }
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            ))}
                        </div>
                        <div className="row">
                            <div className="col offset-lg-3">
                                <hr></hr>
                            </div>
                        </div>
                        <div className="row pb-5 mt-3">
                            <div className={`col-12 col-md-6 offset-md-6 ${Styles.meisterhaftWrapper}`}>
                                <a className="unstyled" href="https://www.bauwirtschaft-rlp.de/de/Verband/Meisterhaft-Kampagne/Qualitaetsversprechen" target="_blank" rel="noopener noreferrer">
                                    <img
                                      src="/images/meisterhaft-klimaschutz-5-sterne.jpg"
                                      alt="Meisterhaft-Siegel mit 5 Sternen"
                                      height="75"
                                      loading="lazy"
                                    ></img>
                                </a>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </React.Fragment>
    );
}
